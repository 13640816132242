<template>
  <section>
    <b-card>
      <div class="text-right">
        <feather-icon
          v-if="canEdit"
          v-b-modal.share-modal
          icon="Share2Icon"
          :title="$t('share.tooltip')"
          size="20"
          class="text-primary share-icon"
        />
      </div>
      <organizations-paginated-table
        :type-key="typeKey"
        :per-page="20"
        :listing-type="0"
        :is-societies="true"
      />
      <b-modal
        id="share-modal"
        :title="$t('share.title')"
        no-close-on-backdrop
        hide-footer
      >
        <template #modal-ok>
          <feather-icon icon="Share2Icon" size="14" />
          {{ $t('share.title') }}
        </template>
        <p>{{ $t('share.select-multiple-organizations') }}:</p>
        <ajax-select
          v-model="organizationsSelected"
          :clearable="false"
          multiple
          searchable
          :placeholder="$t('form.select-multiple-placeholder')"
          dir="ltr"
          input-id="profile-organizations"
          :fetch-data="fetchOrganizationsPage"
          :options="organizations"
          label="name"
          class="my-1 select-multiple"
        />
        <share-communities-select
          type="organizations"
          :model-key="selectedKeys"
          model-type="organization"
          @morph-type-updated="handleMorphTypeUpdated()"
          @change="closeModal"
        />
      </b-modal>
    </b-card>
  </section>
</template>

<script>
import OrganizationsPaginatedTable from '@/views/apps/organization/components/OrganizationsPaginatedTable.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import AjaxSelect from '@core/components/ajax-select/AjaxSelect.vue';
import ShareCommunitiesSelect from '@/@core/components/ShareCommunitiesSelect.vue'

export default {
  name: 'OrganizationsList',
  components: {
    OrganizationsPaginatedTable,
    AjaxSelect,
    ShareCommunitiesSelect,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    typeKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      communitiesSelected: [],
      allCommunities: [],
      organizationsSelected: [],
      isLoading: false,
      currentType: '',
      currentPage: 1,
      communitySelected: '',
    };
  },
  computed: {
    selectedKeys() {
      return this.organizationsSelected.map(({ key }) => key);
    },
    type() {
      if (this.$route.params.type) {
        return this.$route.params.type;
      }
      return null;
    },
    listingType() {
      return 0;
    },
    translatedType() {
      if (this.type) {
        return translateTranslationTable(
          this.$store.getters.currentLocale,
          typeof this.type === 'string' ? this.type : this.type.namePlural,
        );
      }
      return null;
    },
    canEdit() {
      return this.$store.getters.currentCollective.isStaff;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    organizations() {
      if (this.$store.getters.shareOrganizations[this.currentType]) {
        return this.$store.getters.shareOrganizations[this.currentType].unpaginated;
      }
      return [];
    },
  },
  watch: {
    translatedType(translatedType) {
      this.updateBreadcrumbs(translatedType);
    },
    typeKey() {
      this.$store.commit('app/SET_BREADCRUMBS', null);
    },
  },
  async created() {
    this.isLoading = true;
    this.fetchCommunities();
    this.currentType = this.typeKey ? this.typeKey : this.communitySlug;
    this.updateBreadcrumbs(this.translatedType);
    this.isLoading = false;
  },
  methods: {
    updateBreadcrumbs(translatedType) {
      let breadcrumbs;
      if (this.type) {
        breadcrumbs = [
          {
            text: 'organizations.breadcrumb-text.organizations-list',
            to: { name: 'organizations' },
          },
          {
            text: translatedType,
            active: true,
          },
        ];
      } else {
        breadcrumbs = [
          {
            text: 'organizations.breadcrumb-text.organizations-list',
            active: true,
          },
        ];
      }
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    async fetchCommunities(page, searchString = '', force = true) {
      if (searchString === '') {
        return this.$store.dispatch('getItems', {
          itemType: 'communities',
          page,
          forceAPICall: force,
          perPage: this.perPage,
          requestConfig: {
            getOfSame: true,
          },
        });
      }
      return this.$store.dispatch('getItems', {
        itemType: 'communities',
        page,
        forceAPICall: force,
        perPage: this.perPage,
        requestConfig: {
          searchString,
        },
      });
    },
    // async share() {
    //   const organizationsKeys = this.organizationsSelected.map(({ key }) => key);
    //   const communitiesKeys = this.communitiesSelected.map(({ key }) => key);
    //   try {
    //     await this.$store.$service[Service.BackendClient].post('/shareddocuments', {
    //       modelType: 'organization',
    //       modelKey: organizationsKeys,
    //       targetCommunityKey: communitiesKeys,
    //     });
    //     this.notifySuccess(this.$t('share.notify-success'));
    //     this.communitiesSelected = [];
    //     this.organizationsSelected = [];
    //   } catch (error) {
    //     this.notifyError(this.$t('share.notify-error'));
    //   }
    // },
    async fetchOrganizationsPage(page, searchString = '', force = true) {
      this.isLoading = true;
      this.currentType = this.typeKey ? this.typeKey : this.communitySlug;
      if (this.$store.getters.shareOrganizations[this.currentType]?.unpaginated.length === 1) {
        force = true;
      }
      if (searchString === '') {
        return this.$store.dispatch('getItems', {
          itemType: 'organizations',
          customName: 'shareOrganizations',
          page,
          storedKey: this.currentType,
          forceAPICall: force,
          perPage: this.perPage,
          requestConfig: {
            listingType: this.listingType,
            orderByName: 1,
            typeKey: this.currentType !== this.communitySlug ? this.currentType : undefined,
          },
        });
      }
      return this.$store.dispatch('getItems', {
        itemType: 'organizations',
        customName: 'shareOrganizations',
        page,
        storedKey: this.currentType,
        forceAPICall: force,
        perPage: this.perPage,
        requestConfig: {
          searchString,
          listingType: this.listingType,
          orderByName: 1,
          typeKey: this.currentType !== this.communitySlug ? this.currentType : undefined,
        },
      });
    },
    closeModal() {
      this.$bvModal.hide('share-modal');
    },
  },
};
</script>
<style lang="scss" scoped>
.share-icon {
  margin-top: -130px;
  cursor: pointer;
}
</style>
