<template>
  <div>
    <p class="font-weight-bold">{{ $t('share.select-space') }}:</p>
    <v-select
      v-model="morphType"
      clearable
      label="name"
      :placeholder="$t('form.select-placeholder')"
      :get-option-label="(a) => a.name"
      :reduce="(a) => a.code"
      multiple
      :options="morphTypeOptions"
      @change="emit('morph-type-updated', true)"
    />
    <p class="font-weight-bold mt-2">{{ $t('share.select-message') }}:</p>
    <ajax-select
      v-model="communitySelected"
      clearable
      searchable
      :disabled="morphType.length == 0"
      class="mt-1 select-multiple"
      dir="ltr"
      input-id="shared-communities"
      :placeholder="$t('form.select-placeholder')"
      :fetch-data="fetchCommunities"
      :calling-again="callingAgain"
      label="name"
      :has-code="true"
      :multiple="true"
      :code="communitiesFilter"
      @on-close="callingAgain = false"
    />
    <b-button
      :disabled="isDisabledButton"
      variant="primary"
      size="sm"
      class="mb-1 text-right mt-1"
      @click="share"
    >
      <feather-icon icon="Share2Icon" size="14" />
      {{ $t('share.title') }}
    </b-button>
  </div>
</template>
<script>
import vSelect from 'vue-select';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import AjaxSelect from '@core/components/ajax-select/AjaxSelect.vue';
import { MainType } from '@copernicsw/community-common';

export default {
  name: 'ShareModal',
  components: {
    vSelect,
    AjaxSelect,
  },
  mixins: [ToastNotificationsMixin],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    itemName: {
      type: String,
      required: false,
    },
    modelType: {
      type: String,
      required: true,
    },
    modelKey: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    mediaType: {
      type: String,
      required: false,
      default: 'audio',
    },
    canShare: Boolean,
    showMe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCommunityActive: false,
      communitySelected: '',
      morphType: [],
      callingAgain: false,
    };
  },
  computed: {
    isDisabledButton() {
      return this.morphType.length == 0 || this.communitySelected === '' || this.communitySelected?.length === 0;
    },
    morphTypeOptions() {
      return [
        { name: this.$t("communities"), code: 6 },
        { name: this.$t("events.title"), code: MainType.Event },
        { name: this.$t("courses.title"), code: MainType.Course },
        { name: this.$t("projects.title"), code: MainType.Project },
        { name: this.$t("organizations.title"), code: MainType.Organization },
        { name: this.$t("challenges.title"), code: MainType.Challenge },
        { name: this.$t("services.title"), code: MainType.Service },
      ];
    },
  },
  watch: {
    morphType() {
      this.callingAgain = true;
    },
    callingAgain(value) {
      return value;
    },
  },
  methods: {
    communitiesFilter(response) {
      const communityOptions = [];
      for (const community of response) {
        if (community) {
          communityOptions.push({ code: community.key, name: community.name });
        }
      }
      return communityOptions;
    },
    fetchCommunities(page, searchString = '' ) {
      return this.$store.dispatch('getItems', {
        itemType: 'communities/wholeBranch',
        customName: 'share',
        page,
        perPage: 15,
        forceAPICall: true,
        requestConfig: {
          searchString: searchString || '',
          mainTypeIDs: this.morphType.includes(6) ? [...this.morphType, ...[7]] : this.morphType,
          showMe: this.showMe,
        },
      });
    },
    async share() {
      try {
        const key = {};
        key[`${this.modelType}Key`] = this.modelKey;
        const requestConfig = {
          ...key,
          sharedCommunityKey: this.communitySelected,
        };
        if (this.type === 'media') {
          requestConfig.mediaType = this.mediaType;
        }
        await this.$store.dispatch('postItem', {
          type: `/${this.type}/share`,
          requestConfig,
        });
        this.$emit('change', true);
        this.notifySuccess(this.$t('share.notify-success'));
      } catch (error) {
        this.notifyError(this.$t('share.notify-error'));
      }
    },
  },
};
</script>
